import { template as template_ff10d34b970241ec90afc8e1cd7fdaad } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_ff10d34b970241ec90afc8e1cd7fdaad(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
