import { template as template_40e11475fb2645f68e3aab2c87e5f8a1 } from "@ember/template-compiler";
const FKLabel = template_40e11475fb2645f68e3aab2c87e5f8a1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
