import { template as template_db20cb8598404cba8102d87620f051a5 } from "@ember/template-compiler";
const WelcomeHeader = template_db20cb8598404cba8102d87620f051a5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
