define("discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-topic-status", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupTrackerTopicStatus extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get titleName() {
      const trackedGroups = this.site && this.site.tracked_groups;
      const trackedGroup = trackedGroups.find(g => g.name === this.args.topic.first_tracked_post.group);
      let title = "";
      if (trackedGroup) {
        const name = trackedGroup.full_name ? trackedGroup.full_name : trackedGroup.name;
        title = _discourseI18n.default.t("group_tracker.first_group_post", {
          group: name
        });
      }
      return title;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="topic-icon-container">
          {{#if @topic.first_tracked_post}}
            <a
              class="tracked-post group-{{@topic.first_tracked_post.group}}"
              href="{{@topic.url}}/{{@topic.first_tracked_post.post_number}}"
              title={{this.titleName}}
            >
              {{icon @topic.firstTrackedPostIcon class="first-tracked-post"}}
              {{#if this.siteSettings.group_tracker_topic_icon}}
                {{icon
                  this.siteSettings.group_tracker_topic_icon
                  class=this.siteSettings.group_tracker_topic_icon_class
                }}
              {{/if}}
            </a>
          {{else}}
            {{#if this.siteSettings.group_tracker_topic_icon}}
              {{icon
                this.siteSettings.group_tracker_topic_icon
                class=this.siteSettings.group_tracker_topic_icon_class
              }}
            {{/if}}
          {{/if}}
        </span>
      
    */
    {
      "id": "cZESvDJI",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"topic-icon-container\"],[12],[1,\"\\n\"],[41,[30,1,[\"first_tracked_post\"]],[[[1,\"        \"],[10,3],[15,0,[29,[\"tracked-post group-\",[30,1,[\"first_tracked_post\",\"group\"]]]]],[15,6,[29,[[30,1,[\"url\"]],\"/\",[30,1,[\"first_tracked_post\",\"post_number\"]]]]],[15,\"title\",[30,0,[\"titleName\"]]],[12],[1,\"\\n          \"],[1,[28,[32,0],[[30,1,[\"firstTrackedPostIcon\"]]],[[\"class\"],[\"first-tracked-post\"]]]],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"group_tracker_topic_icon\"]],[[[1,\"            \"],[1,[28,[32,0],[[30,0,[\"siteSettings\",\"group_tracker_topic_icon\"]]],[[\"class\"],[[30,0,[\"siteSettings\",\"group_tracker_topic_icon_class\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"siteSettings\",\"group_tracker_topic_icon\"]],[[[1,\"          \"],[1,[28,[32,0],[[30,0,[\"siteSettings\",\"group_tracker_topic_icon\"]]],[[\"class\"],[[30,0,[\"siteSettings\",\"group_tracker_topic_icon_class\"]]]]]],[1,\"\\n\"]],[]],null]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@topic\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-topic-status.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GroupTrackerTopicStatus;
});