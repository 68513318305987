import { template as template_aaadd776ae3d4fca8145a8d9c3b64034 } from "@ember/template-compiler";
const SidebarSectionMessage = template_aaadd776ae3d4fca8145a8d9c3b64034(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
